import flow from 'lodash/flow';
import { takeLatest, select, call, put } from 'redux-saga/effects';
import groupBy from 'lodash/groupBy';
import sortBy from 'lodash/sortBy';
import format from 'date-fns/format';

import toaster from '@spot/shared-store/toaster';

import postApiTilesService from '@spot/shared-services/temporal/postApiMaps.service';
import getApiMapsSatelliteImagesImageIdService from '@spot/shared-services/temporal/getApiMapsSatelliteImagesImageId.service';

import currentState from '.';
import analyse from '../../analyse';

const handleServicePost = function* (action) {
  const { payload } = action;

  const geojsonAnalyse = yield select(analyse.selector.selectData);

  const handlers = {
    fetchStart: flow(currentState.action.fetchStart, put),
    fetchEnd: flow(currentState.action.fetchEnd, put),
    fetchError: flow(currentState.action.fetchError, put),
    updateFilters: flow(currentState.action.updateFilters, put),
    updateTile: flow(currentState.action.updateTile, put),
    show: flow(toaster.action.show, put),
  };

  try {
    yield handlers.updateFilters(payload);

    yield handlers.fetchStart();

    const [success, result] = yield call(postApiTilesService, {
      ...payload,
      geometry: {
        ...geojsonAnalyse?.results?.geom,
        coordinates: geojsonAnalyse?.results?.geom?.coordinates.map((c) =>
          c.map((cm) => [cm[0], cm[1]])
        ),
      },
      date_begin:
        payload?.date_begin &&
        format(new Date(payload?.date_begin), 'yyyy-MM-dd'),
      date_end:
        payload?.date_end && format(new Date(payload?.date_end), 'yyyy-MM-dd'),
    });

    if (!success) {
      throw result;
    }

    yield handlers.updateTile(result?.tileUrl);

    // const imagesGrouped: any = groupBy(result?.images, 'date');

    // const imagesFormatted = Object.values(
    //   Object.keys(imagesGrouped).reduce((acc, curr) => {
    //     return {
    //       ...acc,
    //       [curr]: sortBy(imagesGrouped[curr], (ig) => ig.cloud_cover)[0],
    //     };
    //   }, {})
    // );

    return yield handlers.fetchEnd({
      ...result,
      imagesFormatted: result?.images,
    });
  } catch (result: any) {
    yield handlers.show({
      message: result?.message || 'Aconteceu um erro',
      variant: 'error',
    });

    return yield handlers.fetchError(result);
  }
};

const handleServiceGetMapId = function* (action) {
  const { payload } = action;

  const handlers = {
    fetchStart: flow(currentState.action.fetchStart, put),
    fetchEnd: flow(currentState.action.fetchEnd, put),
    fetchError: flow(currentState.action.fetchError, put),
    show: flow(toaster.action.show, put),
    updateFilters: flow(currentState.action.updateFilters, put),
    updateTile: flow(currentState.action.updateTile, put),
  };

  const selectors = {
    data: yield select(currentState.selector.selectData),
    filters: yield select(currentState.selector.selectFilters),
  };

  try {
    yield handlers.fetchStart();

    const [success, result] = yield call(
      getApiMapsSatelliteImagesImageIdService,
      { image_id: payload.image_id, satellite: selectors.filters.satellite }
    );

    if (!success) {
      throw result;
    }

    yield handlers.updateTile(result?.map_url);

    return yield handlers.fetchEnd(selectors.data);
  } catch (result: any) {
    yield handlers.show({
      message: result?.message || 'Aconteceu um erro',
      variant: 'error',
    });

    return yield handlers.fetchError({
      ...result,
    });
  }
};

function* watch() {
  yield takeLatest(
    currentState.constant.ACTION_TYPES.SERVICE.POST,
    handleServicePost
  );
  yield takeLatest(
    currentState.constant.ACTION_TYPES.SERVICE.GET_MAP_ID,
    handleServiceGetMapId
  );
}

export default {
  watch,
};
