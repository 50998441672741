import flow from 'lodash/flow';
import { takeLatest, select, call, put } from 'redux-saga/effects';
import pickBy from 'lodash/pickBy';
import { v4 as uuidv4 } from 'uuid';

import toaster from '@spot/shared-store/toaster';

import getApiAnalysisIdService from '@spot/shared-services/analysis_esg/getApiAnalysisId.service';

import currentState from '.';
import getStyleByYear from '@spot-spotesg/utils/getStyleByYear.util';

const handleServiceGet = function* (action) {
  const { payload } = action;

  const handlers = {
    fetchStart: flow(currentState.action.fetchStart, put),
    fetchEnd: flow(currentState.action.fetchEnd, put),
    fetchError: flow(currentState.action.fetchError, put),
    show: flow(toaster.action.show, put),
  };

  try {
    yield handlers.fetchStart();

    const [success, result] = yield call(getApiAnalysisIdService, payload);

    if (!success) {
      throw result;
    }

    if (result?.results?.compliances) {
      let index = 0;
      for (const compliance of result.results.compliances) {
        const id = 'compliance_index' + index;
        compliance.id = id;
        compliance.intersection.id = id;
        compliance.properties = {};
        compliance.properties.id = id;

        const legendColors = {
          conservation: '#E0E0E0',
          'semas-manual': '#A25CCF',
          'semas-auto': '#A25CCF',
          ibama: '#FFC857',
          indigenous: '#807D92',
        };

        if (compliance.type_area === 'deforestation') {
          const { color, bgOpacity } = getStyleByYear(`${compliance.year}`);
          compliance.properties.color = color;
          compliance.properties.fillColor = bgOpacity;
        } else {
          compliance.properties.color = legendColors[compliance.type_area];
          compliance.properties.fillColor =
            legendColors[compliance.type_area] + '4D';
        }

        const description = {
          conservation: 'Unidade de Conservação',
          'semas-manual': 'Embargo do SEMAS Manual',
          'semas-auto': 'Embargo do SEMAS-Automático',
          ibama: 'Embargo do IBAMA',
          indigenous: 'Terra Indígena',
          deforestation: 'Desmatamento PRODES',
        };

        compliance.properties.description = description[compliance.type_area];
        index++;
      }
    }

    return yield handlers.fetchEnd(result);
  } catch (result: any) {
    yield handlers.show({
      message: result?.message || 'Aconteceu um erro',
      variant: 'error',
    });

    return yield handlers.fetchError(result);
  }
};

function* watch() {
  yield takeLatest(
    currentState.constant.ACTION_TYPES.SERVICE.GET,
    handleServiceGet
  );
}

export default {
  watch,
};
